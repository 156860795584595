import { TableColumn } from '../shared/table/table.component';

export const columnsPerson: TableColumn[] = [
  {
    field: 'fullname',
    caption: 'IND.NAME_LASTNAME',
    type: 'regular',
  },
  {
    field: 'authorities',
    caption: 'IND.AUTHORITY',
    type: 'regular',
  },
  {
    field: 'stock',
    caption: 'IND.STOCK',
    type: 'regular',
  },
  {
    field: 'powers',
    caption: 'IND.POWER',
    type: 'regular',
  },
  {
    field: 'action',
    caption: 'LABELS.ACTIONS',
    type: 'icon-multi-action',
    multiActionIcons: [
      {
        actionTitle: 'LABELS.EDIT',
        icon: 'edit',
        link: '/individuals/profile/',
        fieldOfRedirection: 'id',
        conditional: false,
      },
      {
        actionType: 'download',
        actionTitle: 'BUTTONS.DOWNLOAD',
        icon: 'download',
      },
      {
        actionType: 'delete',
        conditional: true,
        fieldOfCondition: 'enableDelete',
        actionTitle: 'LABELS.DELETE',
        icon: 'delete_forever',
      },
    ],
  },
];
export const columnLegalPerson: TableColumn[] = [
  {
    field: 'name',
    caption: 'LABELS.NAME',
    type: 'regular',
  },
  {
    field: 'authorities',
    caption: 'IND.AUTHORITY',
    type: 'regular',
  },
  {
    field: 'stock',
    caption: 'IND.STOCK',
    type: 'regular',
  },
  {
    field: 'powers',
    caption: 'IND.POWER',
    type: 'regular',
  },
  {
    field: 'action',
    caption: 'LABELS.ACTIONS',
    type: 'icon-multi-action',
    multiActionIcons: [
      {
        actionTitle: 'LABELS.EDIT',
        icon: 'edit',
        link: '/individuals/profile/',
        fieldOfRedirection: 'id',
        conditional: false,
      },
      {
        actionType: 'download',
        actionTitle: 'BUTTONS.DOWNLOAD',
        icon: 'download',
      },
      {
        actionType: 'delete',
        conditional: true,
        fieldOfCondition: 'enableDelete',
        actionTitle: 'LABELS.DELETE',
        icon: 'delete_forever',
      },
    ],
  },
];
export const powerIndvTable: TableColumn[] = [
  {
    field: "name",
    caption: "LABELS.POA_NAME",
    type: "regular",
  },

  {
    field: "grant_date",
    caption: "LABELS.GRANT_DATE",
    type: "date",
  },
  {
    field: "facultaties",
    caption: "LABELS.POWERS_CONFERRED",
    type: "regular",
  },
  {
    field: "has_files",
    type: "icon-multi-action",
    caption: " ",
    multiActionIcons: [
      /* {
         actionType: "edit",
         svgIcon: "edit-l",
         actionTitle: "LABELS.EDIT",
       },
       {
         actionType: "linkDocs",
         actionTitle: "BUTTONS.LINK_FILES",
       },*/
      {
        actionType: "navigate",
        svgIcon: "visibility-i",
        actionTitle: "INDV.VIEW_POWER",
      }

    ],
  },
];
export const shareholderIndvTable: TableColumn[] = [
  {
    field: "fullname",
    caption: "LABELS.SHAREHOLDER",
    type: "regular",
  },
  {
    field: "percentage",
    caption: "LABELS.OWNERSHIP_PERCENTAJE",
    type: "percentage",
  },
  {
    field: "actions_cuotes",
    caption: "LABELS.AMOUNT_SHARES",
    type: "regular",
  },
  {
    field: "has_files",
    type: "icon-multi-action",
    caption: " ",
    multiActionIcons: [
      /* {
         actionType: "edit",
         svgIcon: "edit-l",
         actionTitle: "LABELS.EDIT",
       },
       {
         actionType: "linkDocs",
         actionTitle: "BUTTONS.LINK_FILES",
       },*/
      {
        actionType: "navigate",
        svgIcon: "visibility-i",
        actionTitle: "INDV.VIEW_SHARES",
      }

    ],
  },
];
export const authIndvTable: TableColumn[] = [
  {
    field: "fullname",
    caption: "LABELS.NAME",
    type: "regular",
  },
  {
    field: "position",
    caption: "LABELS.POSITION",
    type: "regular",
  },
  {
    field: "expiration_date",
    caption: "LABELS.TERM_EXPIRATION",
    type: "date",
  },
  {
    field: "has_files",
    type: "icon-multi-action",
    caption: " ",
    multiActionIcons: [
      /* {
         actionType: "edit",
         svgIcon: "edit-l",
         actionTitle: "LABELS.EDIT",
       },
       {
         actionType: "linkDocs",
         actionTitle: "BUTTONS.LINK_FILES",
       },*/
      {
        actionType: "navigate",
        svgIcon: "visibility-i",
        actionTitle: "INDV.VIEW_AUTHORITY",
      }
    ],
  },
];

export const IndividualsES = {
  DATA: 'Datos de la persona ',
  ADDRESS: 'Direccion',
  DOC: 'Documentos',
  ATTACH_DOCS: 'Documentos Vinculados',
  POWER: 'Apoderado',
  SHAREHOLDER: 'Accionista',
  AUTH: 'Autoridad',
  LEGAL: 'Representante legal',
  VIEW_POWER: 'Ver poder',
  VIEW_SHARES: 'Ver Acciones',
  VIEW_AUTHORITY: 'Ver Autoridades',
  POWERS: 'Poderes',
  NO_LINKS: 'No hay vinculaciones'
};

export const IndividualsEN = {
  DATA: 'Person data',
  ADDRESS: 'Address',
  DOC: 'Documents',
  ATTACH_DOCS: 'Linked documents',
  POWER: 'Proxie',
  SHAREHOLDER: 'Shareholder',
  AUTH: 'Authority',
  LEGAL: 'Legal representative',
  VIEW_POWER: 'View POA',
  VIEW_SHARES: 'View shares',
  VIEW_AUTHORITY: 'View authorities',
  POWERS: 'POA',
  NO_LINKS: 'There is no linkages'
};

export const TabsLinkages = [
  { id: 'powers_companies', label: 'INDV.POWER', links: [] },
  { id: 'shaerholder_linkages', label: 'INDV.SHAREHOLDER', links: [] },
  { id: 'auth_linkages', label: 'INDV.AUTH', links: [] },
  // { id: 'legal_companies', label: 'INDV.LEGAL', links: [] }
];